import React from 'react';
import data from "./accessories.json";  // Import your JSON data
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faYoutube ,faPhone, faMapMarker, faMapMarkedAlt, faPlug, faBolt, faChargingStation, faCalendarAlt, faUniversalAccess,faCreditCard, faLock, faUnlock } from '@fortawesome/free-solid-svg-icons';
import { faFacebook, faTwitter, faLinkedin } from '@fortawesome/free-brands-svg-icons';
import { faEnvelope } from '@fortawesome/free-regular-svg-icons';

function Accessories() {
    const categories = Object.entries(data).map(([category, links], index) => (
        <React.Fragment key={index}>
            <h2>{category}</h2>
            <ul>
                {links.map((link, index) => (
                    <li key={index}>
                        <a href={link.url} target="_blank" rel="noopener noreferrer">{link.title}</a>
                        <p style={{fontSize: '0.8em'}}>{link.description}</p>
                        {/* Share links */}
                        <div style={{fontSize: '0.8em', marginLeft: '20px'}}> 
                            <a href={`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(link.url)}`} target="_blank" rel="noopener noreferrer">
                                <FontAwesomeIcon icon={faFacebook} />  
                            </a> &nbsp; 
                            <a href={`https://twitter.com/intent/tweet?url=${encodeURIComponent(link.url)}`} target="_blank" rel="noopener noreferrer">
                                <FontAwesomeIcon icon={faTwitter} />
                            </a> &nbsp;
                            <a href={`https://www.linkedin.com/shareArticle?mini=true&url=${encodeURIComponent(link.url)}`} target="_blank" rel="noopener noreferrer">
                                <FontAwesomeIcon icon={faLinkedin} />
                            </a> &nbsp;
                            <a href={`mailto:?subject=I wanted to share this link with you&body=${encodeURIComponent(link.url)}`}>
                                <FontAwesomeIcon icon={faEnvelope} />
                            </a>
                        </div>
                    </li>
                ))}
            </ul>
        </React.Fragment>
    ));

    return (
        <div className="stations-container">
        
         <div style={{fontSize: '0.8em'}}>
    <ul style={{display: 'flex', flexDirection: 'row', gap: '30px', listStyleType: 'disc', padding: 0}}>
        <li> 
            <a href="https://evlandus.blogspot.com/2023/07/home-charging.html" target="_blank" rel="noopener noreferrer">Home Charging 101 </a>
        </li>
      
      {/*  <li> 
            <a href="https://evlandus.blogspot.com/2023/07/home-charging.html" target="_blank" rel="noopener noreferrer">Home Charging 101 </a>
        </li>
        <li> 
            <a href="https://evlandus.blogspot.com/2023/07/home-charging.html" target="_blank" rel="noopener noreferrer">Home Charging 101 </a>
    </li> */}
    </ul>
</div>
<div className="station-master">
<div className="accessories">   {categories}</div>
         
        </div>
        </div>
    );
}

export default Accessories;
