import React from 'react';
import data from "./new_arrivals.json"; 

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faVideo } from '@fortawesome/free-solid-svg-icons';

const carModels = data.map((car, index) => {
  let ytUrl = car.yt;

  if (car.youtubeId) {
    ytUrl = `https://www.youtube.com/watch?v=${car.youtubeId[0]}`;
  }

  return (
    <React.Fragment key={index}>
      <h2 style={{marginBottom: '0.05em'}}>
        <FontAwesomeIcon icon={faVideo} />
        
        <il> &nbsp;
          <a href={ytUrl} target="_blank" rel="noopener noreferrer">{car.name}</a>
        </il>

      </h2>

      <ul>
        <li>
          <p style={{fontSize: '0.8em', marginTop: '0.1em'}}>{car.features.join(', ')}</p>
        </li>
      </ul>
    </React.Fragment>
  );
});

function CarModels() {
  return (
    <div className="newarrivals-container">
      <div className="station-master">
        <div className="accessories">
          {carModels}
        </div>
      </div>
    </div>
  );
}

export default CarModels;
