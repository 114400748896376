import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch ,faPhone, faMapMarker, faMapMarkedAlt, faPlug, faBolt, faChargingStation, faCalendarAlt, faUniversalAccess,faCreditCard, faLock, faUnlock } from '@fortawesome/free-solid-svg-icons';
import wordCloudImage from './word-cloud.png';  
import links from "./links.json";
import ReactGA from 'react-ga';
ReactGA.pageview(window.location.pathname + window.location.search);
function Stations() {
    const [stations, setStations] = useState([]);
    const [paramValue, setParamValue] = useState('Fort Lauderdale');
    const [errorMessage, setErrorMessage] = useState(null);
    const [location, setLocation] = useState({});

    const fetchData = async (term = paramValue) => {
        if (!term.trim()) return;
        try {
          const token = '2TidvzmZ0UJh08CEu98g75TPCnR82GgC'; // Replace with your actual token
          const headers = {
          Authorization: `Bearer ${token}`
          };
            const response = await axios.get(`/search/stations?term=${term}`,{ headers });
            setStations(response.data);
            setErrorMessage(null);
        } catch (error) {
            if (error.response && error.response.data[0].station_name) {
                setErrorMessage(error.response.data[0].station_name);
                setStations([error.response.data[0]]);
            } else {
                setErrorMessage('An unexpected error occurred.');
                setStations([]);
            }
        }
    }

    const getLocation = () => {
        if (navigator.geolocation) {
          navigator.geolocation.getCurrentPosition((position) => {
            setLocation({
              lat: position.coords.latitude,
              lon: position.coords.longitude
            });
          }, (error) => {
            console.log(error);
          });
        } else {
          console.log("Geolocation is not supported by this browser.");
        }
      };
    

      const fetchNearbyStations = async () => {
        try {
          const token = '2TidvzmZ0UJh08CEu98g75TPCnR82GgC'; // Replace with your actual token
          const headers = {
          Authorization: `Bearer ${token}`
          };
          getLocation();
          const dist = 10; // search radius in miles
    
          const response = await axios.get(`/search/nearbystations?lat=${location.lat}&lon=${location.lon}&dist=${dist}`,{ headers });
          setStations(response.data);
          setErrorMessage(null);
        } catch (error) {
          if (error.response && error.response.data[0].station_name) {
            setErrorMessage(error.response.data[0].station_name);
            setStations([error.response.data[0]]);
          } else {
            setErrorMessage('An unexpected error occurred.');
            setStations([]);
          }
        }
      };


    useEffect(() => {
        fetchData();
    }, []); // fetch data when the component mounts

    useEffect(() => {
        if (navigator.geolocation) {
          navigator.geolocation.getCurrentPosition(
            (position) => {
              const currentLat = position.coords.latitude;
              const currentLon = position.coords.longitude;
              setLocation({
                lat: currentLat,
                lon: currentLon
              });
            },
            (error) => {
              console.log(error);
            }
          );
        } else {
          console.log('Geolocation is not supported by this browser.');
        }
      }, []);
   
    const formattedDate = (updated_date) => {
        return new Date(updated_date).toISOString().split('T')[0];
    }

    return (
        <div className="stations-container">
          {/* Form to search for stations */}
           <div class="form-container">
        
  <div class="form-input">
  <b>City/Zip</b> &nbsp;&nbsp; 
    <input
      type="text"
      placeholder="city name or zip code"
      value={paramValue}
      onChange={(e) => setParamValue(e.target.value)}
      onKeyDown={(e) => {
        if (e.key === "Enter" && paramValue.trim() !== "") {
          fetchData(paramValue);
          e.preventDefault(); // To prevent form submission
        }
      }}
      className="fullWidthInput"
    />
   
    <button onClick={() => fetchData(paramValue)}>
      <FontAwesomeIcon icon={faSearch} />
    </button>&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;   
    <button class="location-button" onClick={fetchNearbyStations}>  
  Search using current location  
</button>  
  </div>
</div>
    
          {/* Display stations */}
          <div className="station-master">
         
          {stations.map((station) => (
            <div className="station"  key={station.id ? station.id : 'error'}>
          

              <h2>{station.station_name}  {station.dist !== undefined && station.dist !== null && (
                <p>
                  <span className="style-text">Apprx distance:</span> {Math.round(Number(station.dist) > 1.2 ? (Number(station.dist) * 1.5) : Number(station.dist))

} Miles
                </p>
              )}</h2>
    
              {station.station_name.startsWith('No station found') ? null : (
                <>
                  <p>
                    <FontAwesomeIcon icon={faMapMarker} /> {station.street_address}, {station.city}, {station.state}, {station.zip}, {station.country}
                    {station.access_code === 'public' ? (
                      <FontAwesomeIcon icon={faUnlock} style={{ color: 'green' }} />
                    ) : (
                      <FontAwesomeIcon icon={faLock} style={{ color: 'red' }} />
                    )}
                  </p>
                  <a href={`https://www.google.com/maps/search/?api=1&query=${station.street_address},${station.city},${station.state}, ${station.zip}`} target="_blank" rel="noopener noreferrer">
                    <FontAwesomeIcon icon={faMapMarkedAlt} /> Google Map
                  </a>
                  <p>
                    <FontAwesomeIcon icon={faPhone} /> {station.station_phone}
                  </p>
                  <p>
                    <FontAwesomeIcon icon={faPlug} /> {station.ev_network} : <FontAwesomeIcon icon={faBolt} />{Array.isArray(station.ev_connector_types) ? station.ev_connector_types.join(', ') : ''}
{station.ev_connector_types}
                  </p>
                  <p>
                    <FontAwesomeIcon icon={faCreditCard} /> {station.ev_pricing}
                  </p>
                  <p>
                    <FontAwesomeIcon icon={faChargingStation} /> DC : {station.ev_dc_fast_num ? station.ev_dc_fast_num : 0} / LEVEL2 : {station.ev_level2_evse_num ? station.ev_level2_evse_num : 0}
                  </p>
    
                  <p>
                    <FontAwesomeIcon icon={faCalendarAlt} /> {formattedDate(station.updated_at)}
                  </p>
                </>
              )}
            </div>
          ))}
         
        </div>
      
        </div>
      );
}

export default Stations;
