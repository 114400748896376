import React from 'react';
import './App.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faCogs} from '@fortawesome/free-solid-svg-icons';

function AboutUs() {
    return (
        <div className="aboutus-container">
            <div className="aboutus-master">
                <div className="aboutus">
                    <h1>Welcome to EVLand.us</h1>
                    <p>We are a dedicated and dynamic duo, a Software Architect and an Electrical and Computer Engineer with a shared passion for all things electric. Fuelled by our zeal for the environment and the future of sustainable transport, we're here to keep you plugged into the ever-evolving landscape of electric vehicles.</p>
                    <h2>The vision of EVLand.us</h2>
                    <p>Our mission is simple: to provide you with the most reliable, up-to-date, and comprehensive information about electric vehicles. Whether you're an EV owner, enthusiast or simply curious about the world of electric transportation, we are here to support you on your journey. From the latest models and technical specifications to industry news and ecological impacts, we've got you covered.</p>
                    <h2>The future of EVs</h2>
                    <p>As the future unfolds, we believe that electric vehicles are not just an alternative - they are the answer. The answer to sustainable transportation, cleaner air, and a greener planet. By promoting understanding and adoption of electric vehicles, we aim to play our part in steering our world towards a more sustainable future.</p>
                    <p>In the rapidly accelerating world of EVs, it can be tough to keep up with the pace. But don't worry, that's why we're here. At EVLand.us, we navigate the complexities and deliver the essentials, right to your screen.</p>
                    <p><strong>Welcome aboard, and let's drive into a greener future together.</strong></p>
                </div>
            </div> 
        </div>
    );
};

export default AboutUs;
