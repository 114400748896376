import React from 'react';
import data from "./ServiceCenters.json";  // Import your JSON data
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faYoutube ,faPhone, faMapMarker, faMapMarkedAlt, faPlug, faBolt, faChargingStation, faCalendarAlt, faUniversalAccess,faCreditCard, faLock, faUnlock } from '@fortawesome/free-solid-svg-icons';

function ServiceCenters() {
    const categories = Object.entries(data).map(([category, links], index) => (
        <React.Fragment key={index}>
            <h2>{category}</h2>
            <ul>
            {links.map((link, index) => (
    <li key={index}>
        <a href={link.url} target="_blank" rel="noopener noreferrer">{link.title}</a>
        <p style={{fontSize: '0.8em'}}>{link.description}</p>
    {/*   <span style={{fontSize: '0.8em', marginLeft: '20px'}}> 
        <FontAwesomeIcon icon={faPhone} />  <a href={link.video} target="_blank" rel="noopener noreferrer">{link.video_title}</a> 
            </span> */}
    </li>
))}

            </ul>

        </React.Fragment>
    ));

    return (
        <div className="stations-container">
        
         <div style={{fontSize: '0.8em'}}>
    <ul style={{display: 'flex', flexDirection: 'row', gap: '30px', listStyleType: 'disc', padding: 0}}>
        <li> 
            <a href="https://evlandus.blogspot.com/2023/07/home-charging.html" target="_blank" rel="noopener noreferrer">Home Charging 101 </a>
        </li>
      
      {/*  <li> 
            <a href="https://evlandus.blogspot.com/2023/07/home-charging.html" target="_blank" rel="noopener noreferrer">Home Charging 101 </a>
        </li>
        <li> 
            <a href="https://evlandus.blogspot.com/2023/07/home-charging.html" target="_blank" rel="noopener noreferrer">Home Charging 101 </a>
    </li> */}
    </ul>
</div>
<div className="station-master">
<div className="accessories">   {categories}</div>
         
        </div>
        </div>
    );
}

export default ServiceCenters;
