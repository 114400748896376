import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios'; // if axios isn't installed, use `npm install axios` to install it.
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone, faMapMarker } from '@fortawesome/free-solid-svg-icons';
import { faMapMarkedAlt } from '@fortawesome/free-solid-svg-icons';
import { faPlug } from '@fortawesome/free-solid-svg-icons';
import { faBolt, faLeaf } from '@fortawesome/free-solid-svg-icons';
import { faChargingStation } from '@fortawesome/free-solid-svg-icons';
import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons';
import { faUniversalAccess   } from '@fortawesome/free-solid-svg-icons';
import { faLock, faUnlock } from '@fortawesome/free-solid-svg-icons';
import { faCar, faTruck, faCog, faRoad, faRoute, faCity, faTachometerAlt, faGasPump, faDollarSign,faSearch } from '@fortawesome/free-solid-svg-icons';
import ReactGA from 'react-ga';
ReactGA.pageview(window.location.pathname + window.location.search);

const Cars = () => {
  const [paramValue, setParamValue] = useState('audi');
  const [cars, setCars] = useState([]);
  const [errorMessage, setErrorMessage] = useState(null);

  const fetchDataCars = async (term = paramValue) => {
    if (!term.trim()) return;
    try {
      const token = '2TidvzmZ0UJh08CEu98g75TPCnR82GgC'; // Replace with your actual token
      const headers = {
      Authorization: `Bearer ${token}`
      };
        const response = await axios.get(`/search/cars?term=${term}`,{ headers });
        setCars(response.data);
        setErrorMessage(null);
    } catch (error) {
        if (error.response && error.response.data[0].station_name) {
            setErrorMessage(error.response.data[0].station_name);
            setCars([error.response.data[0]]);
        } else {
            setErrorMessage('An unexpected error occurred.');
            setCars([]);
        }
    }
}

useEffect(() => {
  fetchDataCars();
}, []); // fetch data when the component mounts

  return (
  
      <div className="cars-container">
      <div className="form-container">
           
        <div class="form-input">
      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
        </div>
        
        <input 
    className="fullWidthInput"
    type="text" 
    placeholder="make or model" 
    onChange={e => setParamValue(e.target.value)} 
    onKeyDown={e => {
      if (e.key === 'Enter' && paramValue.trim() !== '') {
        fetchDataCars(paramValue);
        e.preventDefault(); // To prevent form submission
      }
    }} 
    
  />
        <button className="location-button1" onClick={() => fetchDataCars(paramValue)}><FontAwesomeIcon icon={faSearch} /></button>
      
     
      
      </div>
      </div>
      <div className="station-master">
        {cars.map((car) => (
          <div className="car" style={{fontSize: '0.9em'}} key={car.id}>
            {/* Replace the below with your actual car properties */}
            <h2>{car.year} {car.make} {car.model} </h2>  
           {/* <button class="location-button" onClick={fetchDataCars}>  
  Search using current location  
        </button>   */}
            <p><FontAwesomeIcon icon={faCar} />   {car.vclass} /  {car.drive}</p>
            <p><FontAwesomeIcon icon={faBolt} />  {car.evmotor}</p>
            <p><FontAwesomeIcon icon={faBolt} />  {car. v240chrgtime}-(Hrs) 240v charge time </p>
            <p><FontAwesomeIcon icon={faTachometerAlt } />  {car.avg_range} Miles (Avg. Range)</p>
            <p><FontAwesomeIcon icon={faLeaf } />  ${car.fuelcost} / year</p>

          </div>
        ))}
    
      </div>
      </div>
  
  );
};

export default Cars;
