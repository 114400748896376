import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './App.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone, faMapMarker, faMapMarkedAlt, faPlug,faCogs, faBolt, faChargingStation, faCalendarAlt, faCreditCard, faLock, faUnlock, faLeaf } from '@fortawesome/free-solid-svg-icons';
import { BrowserRouter as Router, Routes, Route, Link  } from 'react-router-dom';
import Cars from './Cars';
import Stations from './Stations'; // You'll need to create this
import Kb from './Kb';
import ServiceCenters from './ServiceCenters';
import { NavLink } from 'react-router-dom';
import ReactGA from 'react-ga';
import Accessories from './Accessories';
import NewArrivals from './NewArrivals';
import AboutUs from './AboutUs';
import links from "./links.json";

ReactGA.initialize('G-XVEN3GG0W0');

ReactGA.pageview(window.location.pathname + window.location.search);

function App() {
  return (
    <Router>
      <div className="app">
        <header className="app-header">
          <h1><FontAwesomeIcon icon={faLeaf} /> EVLand</h1>
          <h3>Driving Towards a Greener Tomorrow</h3>
          <nav>
            <h4>
   
    <ul className="nav-list">
    
      <li><NavLink to="/Stations" className="nav-link" activeclassname="active-link">Stations</NavLink></li>
      <li><NavLink to="/Cars" className="nav-link" activeclassname="active-link">Cars</NavLink></li>
     {/* <li><NavLink to="/ServiceCenters" className="nav-link" activeclassname="active-link">Service Centers</NavLink></li> */}
     <li><NavLink to="/Accessories" className="nav-link" activeclassname="active-link">Accessories</NavLink></li>
     <li><NavLink to="/ServiceCenters" className="nav-link" activeclassname="active-link">Service Centers</NavLink></li>
     
    

  </ul>

<ul className="nav-list">
<li>
  <a href="https://news.chummar.us" target="_blank" rel="noopener noreferrer" className="nav-link">
    EV News
  </a>
</li>

  <li><NavLink to="/NewArrivals" className="nav-link" activeclassname="active-link">2024 and Beyond</NavLink></li>
  <li><NavLink to="/Kb" className="nav-link" activeclassname="active-link">FAQ</NavLink></li>  
  <li><NavLink to="/AboutUs" className="nav-link" activeclassname="active-link">About Us</NavLink></li> 
</ul>

            </h4>
          </nav>
          <div className="additional-info">
          <ul style={{
    display: 'flex', 
    flexDirection: 'row', 
    gap: '30px', 
    listStyleType: 'disc', 
    padding: 0, 
    justifyContent: 'center', 
    width: '100%'
  }}>  

      {links.map((link) => (
        <li key={link.id}>
        <a href={link.url} target="_blank" rel="noopener noreferrer" style={{color:  "#132f02"  }}>{link.title}</a>
      </li>
        
      ))}
    </ul>
    </div>
 
          
        </header>

        {/* Routes */}
        <Routes>
          <Route path="/" element={<Stations />} /> {/* Default home page to Stations */}
          <Route path="/Stations" element={<Stations />} />
          <Route path="/Cars" element={<Cars />} />
          <Route path="/Accessories" element={<Accessories />} />
          <Route path="/Kb" element={<Kb />} />
          <Route path="/ServiceCenters" element={<ServiceCenters />} />
          <Route path="/NewArrivals" element={<NewArrivals />} />
          <Route path="/AboutUs" element={<AboutUs />} />
        </Routes>
      </div>
      
 
      <div className="footer bg_image">
  <h4><FontAwesomeIcon icon={faCogs} />&nbsp;Mage Theory Solutions &nbsp;&copy; 2023</h4>
  <h4>evland.us@gmail.com</h4>
<p></p>
</div>
    </Router>
    
  );

}

export default App;
